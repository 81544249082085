 
function navFunction() {
   var element = document.getElementById("sideNav");
   element.classList.toggle("hideNavText");

   var element = document.getElementById("content");
   element.classList.toggle("contentResize");

      var element = document.getElementById("logo");
   	element.classList.toggle("logoResize");
}
 


 



//  $(document).ready(function(){
//    $('.owl-carousel').owlCarousel({
//     loop:true,
//     margin:10,
//     responsiveClass:true,
//     responsive:{
//         0:{
//             items:1,
//             nav:true
//         },
//         767:{
//             items:1,
//             nav:false
//         },
//         1000:{
//             items:3,
//             nav:true,
//             loop:false
//         }
//     }
// })

// });

//window scroll function start
$(window).scroll(function() {    

   var scroll = $(window).scrollTop();

   if (scroll >= 225) {
       $("#homeHeader").addClass("darkHeader");
   }
   else
     $("#homeHeader").removeClass("darkHeader");
});
//window scroll function end



// list view grid view function start

var elements = document.getElementsByClassName("custCol");
var i;
function listView() {
 for (i = 0; i < elements.length; i++) {
   elements[i].style.width = "100%";
   elements[i].classList.add("listView");
 }
}
function gridView() {
 for (i = 0; i < elements.length; i++) {
   elements[i].style.width = "33.33%";
   elements[i].classList.remove("listView");   

 }
}
/*var container = document.getElementById("listViewGridView");
var btns = container.getElementsByClassName("btn");
for (var i = 0; i < btns.length; i++) {
 btns[i].addEventListener("click", function() {
   var current = document.getElementsByClassName("active");
   current[0].className = current[0].className.replace(" active", "");
   this.className += " active";
 });
}*/

// list view grid view function end


